import React from 'react';

import Layout from './layout.js';
import DynamicLink from 'components/DynamicLink.js';
import Frog from 'assets/img/frog.png';

const Page404 = () => (
  <Layout>
    <NotFound />
  </Layout>
);

const NotFound = () => (
  <div className="page-404">
    <p className="page-404__overline">404</p>
    <h1 className="page-404__title">
      Oops! There's nothing here except this cute frog...
    </h1>
    <img className="page-404__img" src={Frog} alt="Cute frog" />
    <DynamicLink
      className="page-404__img-caption"
      to="https://www.instagram.com/p/CBwcp8ssPXK/?igshid=7dmd7u39y59q">
      Source
    </DynamicLink>
    <br/>
    <DynamicLink to="/" className="button-link page-404__cta">
      Go back home
    </DynamicLink>
  </div>
);

export default Page404;
